<template>
  <div>
    <v-card class="mb-6 mt-3" elevation="0" outlined v-if="!showEval">
      <containerTitle title="Evaluation"></containerTitle>
      <v-card-text>
        <div v-if="showDecode">
          <v-row class="inlineDiv decodeAsName">
            <v-col class="inlineDiv">
              <associatedOption
                  :fieldAttributes="{ name: 'Decode as',  associatedOption: decodeTypes,  ignoreEmptyAdd:false   }"
                  type="select"
                  :fieldAttrInput="{}"
                  field="decodeAs"
                  v-model="DecodeAs"
                  :templateContent="result"
              ></associatedOption>
            </v-col>
            <v-col class="httpBodyresultActionContainer inlineDiv">
              <errorActionFields
                  v-if="DecodeAs != 'text' &&  DecodeAs != ''"
                  :value="OnDecodeError"
                  @input="updateBodyEvalError"
                  :result="result"
              ></errorActionFields>
            </v-col>
          </v-row>
        </div>
        <lineFieldsArray
            :fieldAttrRow="{}"
            :fields="fields"
            :templateContent="result"
            :value="value.eval"
            @line-field-input="checkInput(...arguments)"
            :headerTitle="true"
            :addable="true"
            addButtTitle="Add Eval"
            :linePrefix="result.stepType + '_' + result.stepID + '_eval'"
        ></lineFieldsArray>
      </v-card-text>
    </v-card>
    <explorerOnError v-model="localValue" :result="result"></explorerOnError>
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import explorerOnError from "@/components/dynamic/explorerOnError.vue";
import lineButtons from "@/cfg/lineButtons.json";
import containerTitle from "@/commonComponents/containerTitle.vue";
import associatedOption from "@/commonComponents/associatedOption.vue";
import errorActionFields from "@/components/legacy/errorActionFields.vue";
import options from "@/cfg/options.json";

export default {
  components: {
    lineFieldsArray,
    explorerOnError,
    containerTitle,
    associatedOption,
    errorActionFields
  },
  data() {
    return {
      operatorList: [],
      rerunsObject: {},
      hideEvalForTheseSteps: ["Explorer_cellular_data_connect",
                          "Explorer_cellular_toggle_call",
                          "Explorer_start_audio_recording",
                          "Explorer_play_audio",
                          "Explorer_cellular_start_conf_call",
                          "Explorer_insert_sim"
                        ],
      showDecodeType: ["Kafka_check_message"],
      decodeTypes: [
        ["JSON", "JSON"],
        ["text", "Text"],
      ],
    };
  },
  props: ["field", "fieldAttributes", "result", "value"],
  computed: {
    DecodeAs: {
      get() {
        let DecodeAs = "JSON";
        if (typeof this.value["DecodeAs"] != "undefined") {
          DecodeAs = this.value["DecodeAs"];
        }
        return DecodeAs;
      },
      set(newVal) {
        let localValue = {};
        if (newVal == "JSON") { //json only need OnDecodeError
          localValue = {...this.value['OnDecodeError'], ...this.value['onError']};
        }else if(newVal == "text"){
          localValue = {...this.value['onError']};
        }
        //console.log(this.value);
        this.$set(localValue, "DecodeAs", newVal);
        this.$emit("input", localValue);
      },
    },
    OnDecodeError() {
      let OnDecodeError = {};
      if (typeof this.value["OnDecodeError"] != "undefined") {
        OnDecodeError = this.value["OnDecodeError"];
      }
      return OnDecodeError;
    },
    showEval() {
      return this.hideEvalForTheseSteps.includes(this.result.stepType);
    },
    showDecode() {
      return this.showDecodeType.includes(this.result.stepType);
    },
    localValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    fields() {
      let fields = {
        type: {
          type: "select",
          associatedOption: this.customTypeOptions(options),
          name: "Type",
          fieldAttrInput: {class: "required"},
        },
        operator: {
          type: "select",
          associatedOption: this.customOperatorOptions(options),
          name: "Operator",
          fieldAttrInput: {class: "required"},
        },
        content: {
          name: "Value",
          inputFieldFormatter: "explorerEvalContentFormatter",
          fieldAttrInput: {class: "required"},
        },
        else: {
          input: false,
          type: "string",
          string: "else",
          colAttrs: {
            style: "max-width: 29px;",
          },
        },
        result: {
          type: "select",
          associatedOption: options.ldapResult,
          name: "Result",
          fieldAttrInput: {class: "required"},
          default: "error",
        },
        action: {
          type: "select",
          associatedOption: options.evalAction,
          name: "Action",
          default: "stopTest",
          fieldAttrInput: {class: "required"},
        },
        info: {
          type: "text",
          name: "Info",
          fieldAttrInput: {class: "", maxlength: '100'},
        },
        remove: lineButtons.remove,
      };

      if (this.result.stepType == "Explorer_USSD" ) { // static content!
        delete fields['type'];
        let staticField = {
          input: false,
          type: "string",
          name: "Type",
          string: "Content",
          colAttrs: {
            cols: 1
          },
        };
        let staticValue = {
          type: "hidden",
          default: "content",
          colAttrs: {class: "d-none"},
        }
        fields['content']['type'] = "text"; //it should be text
        fields = {staticField, "type": staticValue, ...fields};
      }
      if (this.result.stepType == "Explorer_cellular_check_received_sms" || this.result.stepType == "Explorer_at_command") { //sms
        delete fields['type'];
        let customType = {
          input: false,
          type: "string",
          name: "Type",
          string: "Text",
          colAttrs: {
            cols: 1
          },
        };
        let customTypeValue = {
          type: "hidden",
          default: "text",
          colAttrs: {class: "d-none"},
        }
        fields = {customType, "type": customTypeValue, ...fields};
      }
      if (this.result.stepType == "Kafka_check_message") { //kafka
        delete fields['type'];
        if (this.DecodeAs == 'text') {
          let customType = {
            input: false,
            type: "string",
            name: "Type",
            string: "Text",
            colAttrs: {
              cols: 1
            },
          };
          let customTypeValue = {
            type: "hidden",
            default: "text",
            colAttrs: {class: "d-none"},
          }
          fields = {customType, "type": customTypeValue, ...fields};
        } else if (this.DecodeAs == 'JSON') {
          let customType = {
            input: true,
            type: "text",
            name: "Type",
            fieldAttrInput: {class: "required jsonPathCheck"},
            colAttrs: {
              cols: 2
            },
          };
          fields = {"type": customType, ...fields};
        }
      }
      if (this.result.stepType == "Check_CDR") { //CDR
        delete fields['type'];
        let customType = {
          input: true,
          type: "text",
          name: "Type",
          fieldAttrInput: {class: "required jsonPathCheck"},
          colAttrs: {
            cols: 2
          },
        };
        fields = {"type": customType, ...fields};
        }
      if (this.result.stepType == "Explorer_cellular_attach") { //Attach
        delete fields['type'];
        let customType = {
          input: true,
          name: "Type",
          inputFieldFormatter: "explorerEvalTypeFormatter",
          fieldAttrInput: {
            class: "required",
            options:options.explorerCellularAttachType
          },
          colAttrs: {
            cols: 3
          },
        };
        fields = {"type": customType, ...fields};
      }
      return fields;
    },
    checkPath() {
      let checkPath = "";
      let forWhat = this.result.decodeType;
      if (
          typeof forWhat != "undefined" &&
          (forWhat == "JSON" || forWhat == "jwt")
      ) {
        checkPath = "jsonPathCheck";
      } else if (
          typeof forWhat != "undefined" &&
          (forWhat == "text/xml" || forWhat == "XML")
      ) {
        checkPath = "xPathCheck";
      }
      return checkPath + " required";
    },
  },
  methods: {
    updateBodyEvalError(value) {
      let localValue = {...this.value};
      this.$set(localValue, "OnDecodeError", value);
      this.$emit("input", localValue);
    },
    customTypeOptions(optionList) {
      let options = optionList.pingEvalType;
      if (this.result.stepType == "Explorer_TWAMP") {
        options = optionList.explorerTwampEvalType;
      } else if (this.result.stepType == "Check_CDR") {
        options = [["json_path", "JSON PATH"]];
      } else if (this.result.stepType == "Explorer_cellular_receive_call") {
        options = optionList.explorerCellularReceiveCall;
      } else if (this.result.stepType == "Explorer_cellular_voice_call" ) {
        options = optionList.explorerCellularVoiceCall;
      } else if (this.result.stepType == "Explorer_cellular_start_voice_mo" ) {
        options = optionList.explorerCellularStartVoiceCallMO;
      } else if (this.result.stepType == "Explorer_stop_audio_recording" ) {
        options = optionList.explorerCellularStopRecording;
      } else if (this.result.stepType == "Explorer_cellular_hangup_call" ) {
        options = optionList.explorerCellularHangupCall;
      } else if (this.result.stepType == "Explorer_data_usage" ) {
        options =  optionList.explorerDataUsageEvalTypes;
      } else if (this.result.stepType == "Explorer_SMS" ) {
        options =  optionList.explorerSMSMOEvalTypes;
      }/* else if (this.result.stepType == "Explorer_insert_sim" ) {
        options =  optionList.explorerSimInsertEvalTypes;
      }*/
    // console.log(options);
      return options;
    },
    customOperatorOptions(optionList) {
      //console.log(this.result.valueLine);
      let options = optionList.pingEvalOperator;
      if (this.result.stepType == "Explorer_cellular_check_received_sms") {
        options = optionList.smsCheckOperators;
      } else if (this.result.stepType == "Explorer_cellular_attach") {
        options = optionList.operators;
      }
      else if (this.result.stepType == "Kafka_check_message") {
        options = optionList.smsCheckOperators;
        if (this.DecodeAs == 'JSON') {
          options = optionList.correctOperators;
        }
      }
      else if (this.result.stepType == "Check_CDR") {
        options = optionList.correctOperators;   //JSON OPTR
      } else if (this.result.stepType == "Explorer_cellular_voice_call" ||
          this.result.stepType == "Explorer_cellular_start_voice_mo" ||
          this.result.stepType == "Explorer_stop_audio_recording" ||
          this.result.stepType == "Explorer_at_command" ||
          this.result.stepType == "Explorer_data_usage" ||
          this.result.stepType == "Explorer_SMS"
      ) {
        options =  options = optionList.correctOperators;
      }
      return options;
    },
    checkInput(value) {
     // console.log(value)
      let localValue = {...this.value};
      this.$set(localValue, "eval", value);
      this.$emit("input", localValue);
    },
  },
};
</script>